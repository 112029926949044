import React, { useContext, useEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./AdvanceDashboard.css";
import Header from "../../Header/Header";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import "react-circular-progressbar/dist/styles.css";
import "swiper/swiper-bundle.css";
import CanvasJSReact from "@canvasjs/react-charts";
import { Context } from "../../../utils/context";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Accordion from "react-bootstrap/Accordion";
import {
  faCartShopping,
  faBoxOpen,
  faUsers,
  faStore,
} from "@fortawesome/free-solid-svg-icons";
import { Chart } from "react-google-charts";
import DataTable from "datatables.net";
import Button from "react-bootstrap/Button";
import { Link, useLocation } from "react-router-dom";
import { Table } from "react-bootstrap";
import AllProductColumnChart from "./AllProduct-PieChart/Product_pie_chart";
import AllSellerColumnChart from "./AllSellerWiseChart/Product_pie_chart";
import DemoChart from "./DemoChart/demochart";
import DonutDemo from "./DonutChart/donutdemochart";
import DonutDemoPart2 from "./DonutChartpart2/donutdemochart";
import AdminDashboard from "./AdminDashboard";
import VendorDashboard from "./VendorDashboard";

library.add(fas);

const AdvanceDashboard = () => {
  const { getData, usertype } = useContext(Context);
  const [result, setResult] = useState({});
  const getDataAll = async () => {
    const result = await getData("/dashboard");
    setResult(result.data);
  };

  useEffect(() => {
    // getDataAll();
  }, []);

  // search functionality start

  const [filter, setFilter] = useState("");

  const handleFilterChange = (event) => {
    setFilter(event.target.value.toLowerCase());
  };

  const [dashboardData, setDashboardData] = useState("");

  const GetDashboardData = async () => {
    const res = await getData(`/dashbaord`);
    setDashboardData(res?.data);
  };

  const [countOrders, setCountOrders] = useState("");

  const GetEditData = async () => {
    const res = await getData(`/count-all-studio`);
    setCountOrders(res?.data?.count);
  };

  const [countCustomers, setCountCustomers] = useState("");
  const GetCustomersData = async () => {
    const res = await getData(`/count-all-customers`);
    setCountCustomers(res?.data?.count);
  };

  const [countBookings, setCountBookings] = useState("");
  const GetBookingData = async () => {
    const res = await getData(`/count-all-bookings`);
    setCountBookings(res?.data?.count);
  };

  const [countAcceptedBookings, setCountAcceptedBookings] = useState("");
  const GetAcceptedBookingData = async () => {
    const res = await getData(`/count-all-accepted-bookings`);
    setCountAcceptedBookings(res?.data?.count);
  };

  const [countCompletedBookings, setCountCompletedBookings] = useState("");
  const GetCompletedBookingData = async () => {
    const res = await getData(`/count-all-completed-bookings`);
    setCountCompletedBookings(res?.data?.count);
  };

  const [countSellers, setCountSellers] = useState("");
  const GetSellersData = async () => {
    const res = await getData(`/count-all-sellers`);
    setCountSellers(res?.data?.count);
  };
  useEffect(() => {
    GetEditData();
    GetCustomersData();
    GetSellersData();
    GetBookingData();
    GetAcceptedBookingData();
    GetCompletedBookingData();
    GetDashboardData();
  }, []);

  // close button
  const [showBox, setShowBox] = useState(true);

  const handleClose = () => {
    setShowBox(false);
  };

  useEffect(() => {
    const table = new DataTable("#example");
    return () => {
      table.destroy();
    };
  }, []);

  useEffect(() => {
    const table = new DataTable("#exampleContent");
    return () => {
      table.destroy();
    };
  }, []);

  const colors = ["#63bff5", "#f39c12", "#dd4b39", "#00a65a"];

  const [productGraph, setProductGraph] = useState("");


  const [sellerGraph, setSellerGraph] = useState("");
  const [sellerAmount, setSellerAmount] = useState("");

  const [productForGraph, setProductForGraph] = useState([]);
  const [productGraphCount, setProductGraphCount] = useState([]);
  const [productGraphYear, setProductGraphYear] = useState([]);

  const [sellerForGraph, setSellerForGraph] = useState([]);
  const [sellerGraphCount, setSellerGraphCount] = useState([]);

  const [sellerAmountForGraph, setSellerAmountForGraph] = useState([]);
  const [sellerAmountGraphCount, setSellerAmountGraphCount] = useState([]);

  const GetAllProductGraph = async (days) => {
    try {
      const res = await getData(`/year-graph`);
      setProductGraph(res?.data);
    } catch (error) {
      console.error("Error fetching seller data:", error);
    }
  };

  const GetAllSellerGraph = async (days) => {
    try {
      const res = await getData(`/seller-graph`);
      setSellerGraph(res?.data);
    } catch (error) {
      console.error("Error fetching seller data:", error);
    }
  };

  const GetAllSellerAmount = async (days) => {
    try {
      const res = await getData(`/seller-amount`);
      setSellerAmount(res?.data);
    } catch (error) {
      console.error("Error fetching seller data:", error);
    }
  };

  useEffect(() => {
    if (productGraph) {
      // Extracting months
      const extractedProduct = productGraph
        .map((value) => value.month)
        .filter((month) => month !== undefined);
      setProductForGraph(extractedProduct);

      // Extracting counts
      const extractedProductCount = productGraph
        .map((value) => value.totalBookings)
        .filter((count) => count !== undefined);
      setProductGraphCount(extractedProductCount);

      const extractedProductYear = productGraph
        .map((value) => value.year)
        .filter((count) => count !== undefined);
      setProductGraphYear(extractedProductYear);
    }
  }, [productGraph]);

  useEffect(() => {
    if (sellerGraph) {
      // Extracting months
      const extractedProduct = sellerGraph
        .map((value) => value.studio?.name)
        .filter((month) => month !== undefined);
      setSellerForGraph(extractedProduct);

      // Extracting counts
      const extractedProductCount = sellerGraph
        .map((value) => value.totalBookings)
        .filter((count) => count !== undefined);
      setSellerGraphCount(extractedProductCount);
    }
  }, [sellerGraph]);

  useEffect(() => {
    if (sellerAmount) {
      // Extracting months
      const extractedProduct = sellerAmount
        .map((value) => value.studio?.user?.first_name)
        .filter((month) => month !== undefined);
      setSellerAmountForGraph(extractedProduct);

      // Extracting counts
      const extractedProductCount = sellerAmount
        .map((value) => value.adjustedAmount)
        .filter((count) => count !== undefined);
      setSellerAmountGraphCount(extractedProductCount);
    }
  }, [sellerAmount]);

  useEffect(() => {
    GetAllProductGraph();
    GetAllSellerGraph();
    GetAllSellerAmount();
  }, []);

  return (
    <div className="main-advancedashboard">
      <section className="AdvanceDashboard p-0">
        <div className="AdvanceDashboardTabs">
          <Header title={"Dashboard"} link={"/masters/social_links"} />
          <div className=""></div>
        </div>
      </section>
      <div className="row me-0 ms-0">
        <div className="col-xxl-12 col-xl-12  ">
          <div className="row MainRowsec ms-0 me-0">
            <div className="Main-Section-Advanced-Dashboard">
              <h1 className="title"></h1>

              {usertype === "Admin" ? (
                <AdminDashboard
                  dashboardData={dashboardData}
                  productForGraph={productForGraph}
                  productGraphCount={productGraphCount}
                  productGraphYear={productGraphYear}
                  sellerForGraph={sellerForGraph}
                  sellerGraphCount={sellerGraphCount}
                  sellerAmountGraphCount={sellerAmountGraphCount}
                  sellerAmountForGraph={sellerAmountForGraph}
                />
              ) : (
                <VendorDashboard
                  dashboardData={dashboardData}
                  productForGraph={productForGraph}
                  productGraphCount={productGraphCount}
                  productGraphYear={productGraphYear}
                  sellerForGraph={sellerForGraph}
                  sellerGraphCount={sellerGraphCount}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvanceDashboard;
