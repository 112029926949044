import React from "react";
import ReactApexChart from "react-apexcharts";

function DemoChart({ productData, productCount, year }) {
  const series = [
    {
      name: "Accepted Booking",
      data: productCount, // The values for each product category
    },
  ];

  const options = {
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: productData.map((month, index) => `${month} ${year[index]}`), // Combine month and year
      // Labels for the X-axis
      title: {
        text: "Month", // Title for the X-axis
        style: {
          fontSize: "14px",
          fontWeight: "bold",
        },
      },
    },

    yaxis: {
      title: {
        text: "Booking Count", // Title for the Y-axis
        style: {
          fontSize: "14px",
          fontWeight: "bold",
        },
      },
      labels: {
        formatter: function (value) {
          return value.toFixed(0); // Ensure integer labels
        },
      },
    },
    legend: {
      position: "top",
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + " bookings"; // Tooltip value format
        },
      },
    },
  };

  return (
    <div>
      <div id="chart" style={{ background: "#111" }}>
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height={350}
        />
      </div>
    </div>
  );
}

export default DemoChart;
