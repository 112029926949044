import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

import Modal from "react-bootstrap/Modal";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import classNames from "classnames";

import { walletAmount } from "../../../utils/apis/myAccount/myAccount";
import { Context } from "../../../utils/context";
import Successfull_Modal from "../Successfull_Modal/Successfull_Modal";
import useRazorpay from "react-razorpay";

const Withdraw_Recharge_modal = (props) => {
  const [modalShowSuccess, setModalShowSuccess] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [Razorpay, isLoaded] = useRazorpay();
  const { postData, signin, userdata } = useContext(Context);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  // Handle Razorpay Payment Initialization
  const handleRazorpay = async (data) => {
    const { amount } = data;
    const options = {
      key: "rzp_test_KCP2DQFiHRB3cs",
      amount: amount * 100, // Convert to paise
      currency: "INR",
      name: "Flick And Shoot",
      description: "Test Transaction",
      image: "https://example.com/your_logo",
      handler: async (res) => {
        await submitPayment(res.razorpay_payment_id, amount);
      },
      prefill: {
        name: "Piyush Garg",
        email: "youremail@example.com",
        contact: "9999999999",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzpay = new Razorpay(options);
    rzpay.open();
  };

  // Submit Payment to Backend
  const submitPayment = async (razorpay_id, amount) => {
    try {
      const paydata = {
        razorpay_id,
        user_id: Cookies.get("user_id"),
        amount,
      };

      const response = await walletAmount(paydata);

      if (response?.success) {
        setModalShowSuccess(true); // Show success modal
        navigate("/wallet-history");
        reset(); // Reset the form on success
      } else {
        setError(response?.message || "Payment failed. Please try again.");
      }
    } catch (error) {
      console.error("Error processing payment:", error);
    }
  };

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="withdrawmodal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Wallet Recharge
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form onSubmit={handleSubmit(handleRazorpay)}>
            <div className="col-md-12">
              <div className="main-form-section mt-3">
                <Form.Group>
                  <Form.Label>Amount</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="amount"
                      placeholder="Enter Amount"
                      className={classNames("", {
                        "is-invalid": errors?.amount || error,
                      })}
                      {...register("amount", {
                        required: "Amount is required",
                        validate: (value) => {
                          const numericValue = Number(value);
                          if (!Number.isInteger(numericValue)) {
                            return "Amount must be an integer.";
                          }
                          if (numericValue <= 0) {
                            return "Amount must be greater than 0.";
                          }

                          return true;
                        },
                      })}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      }}
                    />
                  </InputGroup>
                  {errors.amount && (
                    <span className="text-danger">{errors.amount.message}</span>
                  )}
                  {error && <span className="text-danger">{error}</span>}
                </Form.Group>
              </div>
            </div>

            <Modal.Footer>
              <Button className="close-btn" onClick={props.onHide}>
                Close
              </Button>
              <Button className="save-btn" type="submit">
                Recharge
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>

      <Successfull_Modal
        show={modalShowSuccess}
        message="Wallet Recharge Successful"
        onHide={() => setModalShowSuccess(false)}
      />
    </>
  );
};

export default Withdraw_Recharge_modal;
