import React, { useContext, useState } from "react";
import "./Withdrow_request_modal.css";
import Modal from "react-bootstrap/Modal";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { Context } from "../../../utils/context";
import classNames from "classnames";
import { useForm } from "react-hook-form";
import Successfull_Modal from "../Successfull_Modal/Successfull_Modal";

const Withdrow_request_modal = (props) => {
  const { postData, signin, userdata } = useContext(Context);
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
    getValues,
    reset,
  } = useForm();


  const [successModal, setSuccessModal] = useState(false);

  const onSubmit = async (data) => {
    const res = await postData(`/wallet-request/add`, data);
    if (res?.success) {
      setSuccessModal(true);
      setTimeout(() => {
        setSuccessModal(false);
        props.onHide(true);
        reset();
        window.location.reload();
      }, 3000);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };
  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="save-modal upload-modal Withdrow_request_modal"
      >
        <Modal.Body className="text-start">
          <Form role="form">
            <Modal.Body>
              <div className="col-md-12">
                <Form.Label>
                  <h3>Withdraw Request</h3>
                </Form.Label>
                <div className="main-form-section mt-3">
                  <Form.Group>
                    <Form.Label>Amount</Form.Label>

                    <InputGroup>
                      <Form.Control
                        type="number"
                        name="amount"
                        placeholder="Amount"
                        className={classNames("", {
                          "is-invalid": errors?.amount,
                        })}
                        {...register("amount", {
                          required: "Amount is required",
                          validate: (value) => {
                            const numericValue = Number(value);

                            // Check if the value is a number and an integer
                            if (!Number.isInteger(numericValue)) {
                              return "Amount must be an integer.";
                            }

                            // Check if the value is non-negative
                            if (numericValue < 0) {
                              return "Amount cannot be negative.";
                            }

                            // Check if the amount is less than or equal to wallet balance
                            if (numericValue > userdata?.amount) {
                              return `Insufficient Wallet Balance. Available: ${userdata?.amount}`;
                            }

                            // If all conditions are satisfied, return true
                            return true;
                          },
                        })}
                      />
                    </InputGroup>
                    {errors.amount && (
                      <span className="text-danger">
                        {errors.amount.message}
                      </span>
                    )}
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Account Number</Form.Label>

                    <InputGroup>
                      <Form.Control
                        type="number"
                        name="account_no"
                        placeholder="Account Number "
                        className={classNames("", {
                          "is-invalid": errors?.account_no,
                        })}
                        {...register("account_no", {
                          required: "Account Number is required",
                        })}
                      />
                    </InputGroup>
                    {errors.account_no && (
                      <span className="text-danger">
                        {errors.account_no.message}
                      </span>
                    )}
                  </Form.Group>{" "}
                  <Form.Group>
                    <Form.Label>IFSC Code</Form.Label>

                    <InputGroup>
                      <Form.Control
                        type="text"
                        name="ifsc_code"
                        placeholder="IFSC Code"
                        className={classNames("", {
                          "is-invalid": errors?.ifsc_code,
                        })}
                        {...register("ifsc_code", {
                          required: "IFSC Code is required",
                        })}
                      />
                    </InputGroup>
                    {errors.ifsc_code && (
                      <span className="text-danger">
                        {errors.ifsc_code.message}
                      </span>
                    )}
                  </Form.Group>
                </div>
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button className="close-btn" onClick={props.onHide}>
                Close
              </Button>
              <Button className="save-btn" onClick={handleSubmit(onSubmit)}>
                Withdraw
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>{" "}
      <Successfull_Modal
        message={"Request Send Successfully"}
        show={successModal}
        onHide={() => setSuccessModal(false)}
      />
    </div>
  );
};

export default Withdrow_request_modal;
