import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../Tabels/Tabels.css";
import plus from "../../Components/assets/icons/a1.png";
import colunms from "../../Components/assets/icons/LINES.png";
import search1 from "../../Components/assets/icons/search.png";
import top from "../../Components/assets/icons/top.png";
import Table from "react-bootstrap/Table";
import { Link, useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import { Context } from "../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toast, { Toaster } from "react-hot-toast";
import AddOffCanvance from "./Add";
import EditOffCanvance from "./Edit";
import ModalDelete from "../common/ModelDelete";
import ModelBulkUpload from "../common/ModelBulkUpload";
import { AddButton, EditButton, DeletButton } from "../common/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import { Button, Card, Col, Row } from "react-bootstrap";
import OffcanvasCon from "../OffcanvasCon/OffcanvasCon";
import DataTable from "datatables.net";
import Withdrow_request_modal from "../common/Withdrow_request_modal/Withdrow_request_modal";
import Pagination_Holder from "../common/Pagination_Holder/Pagination_Holder";
import Pagination from "react-bootstrap/Pagination";
import Withdraw_Recharge_modal from "../common/Withdraw_Recharge_modal/Withdraw_Recharge_modal";
library.add(fas);

const Tables = () => {
  const navigate = useNavigate();
  const handleClick = (id) => {
    // navigate(`/employee/employee-details`);
  };

  const handleCustomerClick = (id) => {
    // navigate(`/employee/customer-details`);
  };

  const {
    getData,
    editStatusData,
    deleteData,
    ErrorNotify,
    Per_Page_Dropdown,
    postData,
    getDownloadDataExcel,
    isAllow,
    IMG_URL,
    userdata,
    usertype,
    Select2Data,
    SelectUsers,
  } = useContext(Context);

  const [users, setUsers] = useState();

  const [onPageChange, setonPageChange] = useState(1);

  const [totalPages, settotalPages] = useState();

  const handlePageChange = (pageNumber) => {
    setonPageChange(pageNumber);
  };

  const paginationItems = [];
  for (let page = 1; page <= totalPages; page++) {
    paginationItems.push(
      <Pagination.Item
        key={page}
        active={page === onPageChange}
        onClick={() => handlePageChange(page)}
      >
        {page}
      </Pagination.Item>
    );
  }

  const [modalShow, setModalShow] = React.useState(false);

  const [modalShowRecharge, setModalShowRecharge] = React.useState(false);

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(5);
  const [search, setSearch] = useState("");
  const [reset, setReset] = useState();
  const [show, setShowAdd] = useState(false);
  const [show1, setShowEdit] = useState(0);
  const [changeStatus, setChangeStatus] = useState();
  const [option, setOption] = useState();
  const [showoff, setShowoff] = useState(false);
  const [searchDate, setSearchDate] = useState("");
  const [searchDateTo, setSearchDateTo] = useState("");
  const [searchBookinId, setBookinId] = useState("");
  const [searchUser, setSearchUser] = useState("");
  const [searchWithdrawalStatus, setSearchWithdrawalStatus] = useState("");
  const [withdrawStatus, setWithdrawStatus] = useState({});
  const getDataAll = async () => {
    const response = await getData(
      `/wallet-history?page=${onPageChange}&per_page=${perPage}&term=${search}&from=${
        searchDate || ""
      }&to=${searchDateTo || ""}&searchUser=${
        searchUser?.value || ""
      }&searchWithdrawStatus=${
        searchWithdrawalStatus?.value || ""
      }&booking_id=${searchBookinId || ""}`
    );
    await setData(response);
    setCurrentPage(response?.data?.currentPage);
    setperPage(response?.data?.per_page);
    setSearch(response?.data?.search_name);
    settotalPages(response?.data?.totalPages);

    setOption(await Per_Page_Dropdown(response?.data?.totalEntries));

    const newData = response?.data?.data;
    if (newData) {
      const newIds = newData.map((d) => d?.id);
      setAllChecked(newIds);
    }
  };

  useEffect(() => {
    getDataAll();
  }, [
    changeStatus,
    perPage,
    reset,
    show,
    show1,
    searchDateTo,
    searchDate,
    search,
    onPageChange,
    searchUser,
    searchWithdrawalStatus,
    searchBookinId,
  ]);

  const ChangeStatus = async (id) => {
    const response = await editStatusData(`/wallet-history/${id}`);
    setChangeStatus(response);
  };

  const GetAllOrdderStatus = async () => {
    const response = await getData("/all-withdraw-status");

    if (response?.success) {
      setWithdrawStatus(await Select2Data(response?.data, "order_status_id"));
    }
  };

  const [walletDashboard, setWalletDashboard] = useState("");

  const GetWalletDashboard = async () => {
    const res = await getData(`/wallet-dashboard`);
    setWalletDashboard(res?.data);
  };

  useEffect(() => {
    GetWalletDashboard();
    GetAllOrdderStatus();
  }, []);

  // Modal function
  const handleClose = () => setShowAdd(false);
  const handleShow = () => setShowAdd(true);

  const handleClose1 = () => setShowEdit(0);
  const handleShow1 = (id) => {
    setShowEdit(id);
  };

  // Select All Functionality
  const [selectAllChecked, setSelectAllChecked] = useState([]);
  const [allChecked, setAllChecked] = useState([]);

  const handleSelectAll = async () => {
    await setSelectAllChecked(allChecked);
  };

  const handleChange = async (e) => {
    const { value, checked } = e.target;

    if (value === "selectAll") {
      if (checked) {
        handleSelectAll();
      } else {
        await setSelectAllChecked([]);
      }
    } else {
      if (checked) {
        await setSelectAllChecked([...selectAllChecked, Number(value)]);
      } else {
        await setSelectAllChecked(
          selectAllChecked.filter((e) => e !== Number(value))
        );
      }
    }
  };

  // Delete module
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [recordToDeleteId, setRecordToDeleteId] = useState(null);
  const [recordToDeleteName, setRecordToDeleteName] = useState(null);

  const showDeleteRecord = async (id, name) => {
    setShowDeleteModal(true);
    setRecordToDeleteId(id);
    setRecordToDeleteName(name);
  };

  const handleDeleteRecord = async () => {
    setShowDeleteModal(false);
    if (recordToDeleteId) {
      const response = await deleteData(`/subscription/${recordToDeleteId}`);
      // notify("error", "Deleted Succefully");

      ErrorNotify(recordToDeleteName);

      setRecordToDeleteId(null);
      setRecordToDeleteName(null);
      setChangeStatus(response);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setRecordToDeleteId(null);
    setRecordToDeleteName(null);
  };

  //  column hide and show.....
  const [visible, setVisibel] = useState({
    col0: true,
    col1: true,
    col2: true,
    col3: true,
    col4: true,
    col5: true,
    col6: true,
    col7: true,
    col8: true,
    col9: true,
    col10: true,
    col11: true,
    col12: true,
    col13: true,
    col14: true,
    col15: true,
    col16: true,
  });

  //toggle columnns.........
  const toggleColumn = (event, columnName) => {
    event.preventDefault();
    setVisibel((prev) => ({
      ...prev,
      [columnName]: !prev[columnName],
    }));
  };

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  useEffect(() => {
    const table = new DataTable("#example");
    return () => {
      table.destroy();
    };
  }, []);

  function formatDate(dateString) {
    if (!dateString) {
      return "Date not available"; // Fallback if the date is null or undefined
    }

    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return "Invalid date"; // Handle invalid date
    }

    return date.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  }

  // Example usage

  const GetAllUsers = async () => {
    const response = await getData("/allusers");
    if (response?.success) {
      setUsers(await SelectUsers(response?.data, "city_id"));
    }
  };

  useEffect(() => {
    GetAllUsers();
  }, []);

  return (
    <>
      <div className="main-advancedashboard">
        <Header title={"Wallet History"} link={"/subscription"} />
        <section className="AdvanceDashboard">
          <div className="row me-0 ms-0">
            <div className="col-xxl-12 col-xl-12  ">
              <div className=" MainRowsec ms-0 me-0">
                <div className="Main-Section-Advanced-Dashboard">
                  {/* <h1 className="title">Home</h1> */}
                  <Row>
                    {/* {usertype == "Admin" && (
                      <Col
                        xxl={2}
                        xl={3}
                        lg={3}
                        md={4}
                        sm={6}
                        xs={12}
                        className="mb-3"
                      >
                        <Card className="small-box">
                          <div>
                            <div className="content">
                              <div class="inner">
                                <h3>
                                  <span> ₹ </span>
                                  {walletDashboard?.totalAmount
                                    ? walletDashboard?.totalAmount
                                    : 0}
                                </h3>
                                <p>Total Wallet Amount</p>
                              </div>
                            </div>
                          </div>
                        </Card>
                      </Col>
                    )} */}
                    {/* {usertype == "Admin" && (
                      <Col
                        xxl={2}
                        xl={3}
                        lg={3}
                        md={4}
                        sm={6}
                        xs={12}
                        className="mb-3"
                      >
                        <Card className="small-box">
                          <div>
                            <div className="content">
                              <div class="inner">
                                <h3>
                                  <span> ₹ </span>
                                  {walletDashboard?.sellerAmount
                                    ? walletDashboard?.sellerAmount
                                    : 0}
                                </h3>
                                <p>Total Vendors Wallet</p>
                              </div>
                            </div>
                          </div>
                        </Card>
                      </Col>
                    )} */}
                    <Col
                      xxl={2}
                      xl={3}
                      lg={3}
                      md={4}
                      sm={6}
                      xs={12}
                      className="mb-3"
                    >
                      <Card className="small-box">
                        <div>
                          <div className="content">
                            <div class="inner">
                              <h3>
                                <span> ₹ </span>
                                {userdata?.amount ? userdata?.amount : 0}
                              </h3>
                              <p>My Wallet Amount</p>
                            </div>
                          </div>

                          {/* <Link>
                            <p className="mb-0 small-box-footer">
                              More info{" "}
                              <FontAwesomeIcon icon="fa-solid fa-circle-arrow-right" />
                            </p>
                          </Link> */}
                        </div>
                      </Card>
                    </Col>
                  </Row>
                </div>

                <section className="Tabels tab-radio tab-radio ">
                  <div className="">
                    {/* container */}
                    <div className="row">
                      <div className="row">
                        {}
                        <div className="d-flex align-items-center justify-content-between">
                          {/* <div className="wallate-ammount-text-holder">
                            <p className="mb-0">
                              <b>wallet Amount :-{userdata?.amount}</b>
                            </p>
                          </div> */}

                          {usertype == "vendor" && (
                            <div className="add wallet-add-btn-holder">
                              <button
                                className="btn btn-columns w-auto"
                                type="button"
                                onClick={() => setModalShow(true)}
                              >
                                Withdraw Request
                              </button>
                            </div>
                          )}

                          {/* {usertype == "vendor" && (
                            <div className="add wallet-add-btn-holder">
                              <button
                                className="btn btn-columns w-auto"
                                type="button"
                                onClick={() => setModalShowRecharge(true)}
                              >
                                Wallet Recharge
                              </button>
                            </div>
                          )} */}
                        </div>
                        <div className="border-line mt-3"></div>
                        <div className="row mt-4">
                          <div className=" col-12">
                            <div className="d-flex mb-3 align-items-center">
                              <div className="show me-2">
                                <p className="show m-0">Show</p>
                              </div>
                              <div className="number me-2">
                                <select
                                  className="form-select form-select-sm"
                                  aria-label=".form-select-sm example"
                                  onChange={(e) => setperPage(e.target.value)}
                                >
                                  {option?.map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="entries me-5">
                                <p className="show m-0">entries</p>
                              </div>

                              <div className="col-xxl-2 col-xl-3  col-lg-4  col-md-2 col-12 ">
                                <div className="sowing ">
                                  <p className="show m-0">{`Showing ${Math.min(
                                    (currentPage - 1) * perPage + 1
                                  )} to ${Math.min(
                                    currentPage * perPage,
                                    data?.data?.totalEntries
                                  )} of ${
                                    data?.data?.totalEntries
                                  } entries`}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xxl-12 col-xl-12  col-lg-12  col-md-12 col-12">
                            <div className="row ">
                              <div className="col-xxl-2 col-xl-3  col-lg-4  col-md-6 col-12 mb-2">
                                <Form.Label>Transaction Time From</Form.Label>
                                <div className="num me-2">
                                  {/* <label  className="form-label">From</label> */}
                                  <input
                                    type="date"
                                    className="form-control"
                                    placeholder="from"
                                    value={searchDate}
                                    onChange={(e) =>
                                      setSearchDate(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-xxl-2 col-xl-3  col-lg-4  col-md-6 col-12 mb-2">
                                <Form.Label> Transaction Time To</Form.Label>
                                <div className="num me-2">
                                  <input
                                    type="date"
                                    className="form-control"
                                    placeholder="to"
                                    value={searchDateTo}
                                    min={searchDate}
                                    onChange={(e) =>
                                      setSearchDateTo(e.target.value)
                                    }
                                  />
                                </div>
                              </div>

                              <div className="col-xxl-2 col-xl-3  col-lg-4  col-md-6 col-12 mb-2">
                                <Form.Label>Booking Id</Form.Label>
                                <div className="num me-2">
                                  {/* <label  className="form-label">From</label> */}
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Booking Id"
                                    value={searchBookinId}
                                    onChange={(e) =>
                                      setBookinId(e.target.value)
                                    }
                                  />
                                </div>
                              </div>

                              <div className="col-xxl-1 col-xl-3  col-lg-4  col-md-2 col-12 mb-2 d-flex align-items-end">
                                <Form.Label></Form.Label>
                                <div className="Search-1 w-100">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      setSearch("");
                                      setSearchDate("");
                                      setSearchDateTo("");
                                      setBookinId("");
                                      setSearchUser("");

                                      setReset(!reset);
                                    }}
                                    className="btn btn-reset w-100"
                                  >
                                    Reset
                                  </button>
                                </div>
                              </div>
                              {}
                            </div>
                          </div>
                        </div>

                        {}
                      </div>
                    </div>
                    <div className="border-line mt-3"></div>
                    <div className="row mt-3">
                      {usertype == "Admin" ? (
                        <div className="data">
                          <Table striped bordered hover responsive center>
                            <thead>
                              <tr className="">
                                {visible.col1 && <th className="sr">S. No</th>}
                                <th className="tax-name">Transaction Type </th>
                                <th className="tax-name">User Type </th>
                                {visible.col2 && (
                                  <th className="tax-name">Booking Id </th>
                                )}{" "}
                                {visible.col3 && (
                                  <th className="tax-name">Invoice No</th>
                                )}{" "}
                                {visible.col4 && (
                                  <th className="tax-name">Timestamp </th>
                                )}{" "}
                                {visible.col5 && (
                                  <th className="tax-name">Customer Name </th>
                                )}
                                {visible.col6 && (
                                  <th className="tax-name">Vendor Name</th>
                                )}
                                <th className="tax-name"> Total Amount</th>
                                {/* {visible.col7 && (
                                  <th className="tax-name"> Total Amount</th>
                                )}{" "}
                                {visible.col8 && (
                                  <th className="tax-name"> Vendor Amount</th>
                                )}{" "}
                                {visible.col9 && (
                                  <th className="tax-name"> Commission</th>
                                )} */}
                                {visible.col10 && (
                                  <th className="tax-name"> Payment Mode </th>
                                )}
                                {visible.col11 && (
                                  <th className="tax-name"> Transaction Id </th>
                                )}
                                {visible.col12 && (
                                  <th className="tax-name">
                                    Transaction Time Stamp
                                  </th>
                                )}
                                {visible.col13 && (
                                  <th className="tax-name">
                                    Subscription Start Date
                                  </th>
                                )}
                                {visible.col14 && (
                                  <th className="tax-name">
                                    Subscription End Date
                                  </th>
                                )}
                                {visible.col15 && (
                                  <th className="tax-name">
                                    Subscription Plan Name
                                  </th>
                                )}{" "}
                                {visible.col16 && (
                                  <th className="tax-name">
                                    Subscription Benefits Reedmed
                                  </th>
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              {data?.data?.data?.length > 0 ? (
                                data.data.data.map((d, index) => {
                                  const paginatedIndex =
                                    (onPageChange - 1) * perPage + index + 1;
                                  return (
                                    <tr className="" key={index}>
                                      {visible.col1 && (
                                        <td>{paginatedIndex}.</td>
                                      )}{" "}
                                      <td className="tax-name">
                                        {d?.transaction_type}{" "}
                                      </td>
                                      <td className="tax-name">
                                        {d?.user_type}{" "}
                                      </td>
                                      {visible.col2 && (
                                        <td>{d?.book_studio?.id || "-"}</td>
                                      )}{" "}
                                      {visible.col3 && (
                                        <td>
                                          {d.book_studio?.invoice_no || "-"}
                                        </td>
                                      )}
                                      {visible.col4 && (
                                        <td>
                                          {new Date(d?.createdAt)
                                            .toLocaleString("en-GB", {
                                              day: "2-digit",
                                              month: "2-digit",
                                              year: "numeric",
                                              hour: "2-digit",
                                              minute: "2-digit",
                                              second: "2-digit",
                                              hour12: false, // use `true` for 12-hour format with AM/PM
                                            })
                                            .replace(",", "") || "-"}
                                        </td>
                                      )}{" "}
                                      {visible.col5 && (
                                        <td>
                                          {/* <Link
                                            to={`/employee/customer-details`}
                                            style={{
                                              cursor: "pointer",
                                              color: "blue",
                                            }}
                                          > */}
                                          {/* {d?.book_studio?.user?.first_name +
                                            " " +
                                            d?.book_studio?.user?.last_name}
                                          ({d?.book_studio?.user_id}) */}
                                          {/* </Link> */}
                                          {d?.book_studio?.user?.first_name &&
                                          d?.book_studio?.user?.last_name
                                            ? d?.book_studio?.user?.first_name +
                                              " " +
                                              d?.book_studio?.user?.last_name
                                            : "-"}
                                        </td>
                                      )}
                                      {visible.col6 && (
                                        <td>
                                          {d?.book_studio?.studio?.user
                                            ?.first_name &&
                                          d?.book_studio?.studio?.user
                                            ?.last_name
                                            ? d?.book_studio?.studio?.user
                                                ?.first_name +
                                              " " +
                                              d?.book_studio?.studio?.user
                                                ?.last_name
                                            : "-"}

                                          {/* <Link
                                            to={`/employee/employee-details`}
                                            style={{
                                              cursor: "pointer",
                                              color: "blue",
                                            }}
                                          >
                                            {d?.book_studio?.studio?.user_id}
                                          </Link> */}
                                        </td>
                                      )}{" "}
                                      <td>₹ {d?.amount}</td>
                                      {/* {visible.col7 && (
                                        <td>₹ {d?.book_studio?.amount}</td>
                                      )}
                                      {visible.col8 && (
                                        <td>
                                          ₹{" "}
                                          {(
                                            d?.book_studio?.amount *
                                              (1 - 0.1) || 0
                                          ).toFixed(2)}
                                        </td>
                                      )}
                                      {visible.col9 && (
                                        <td>
                                          ₹{" "}
                                          {(
                                            d?.book_studio?.amount *
                                            (1 - 0.9)
                                          ).toFixed(2)}
                                        </td>
                                      )} */}
                                      {visible.col10 && (
                                        <td>
                                          {d?.book_studio?.payment_type || "-"}
                                        </td>
                                      )}
                                      {visible.col11 && (
                                        <td>
                                          {d?.book_studio?.payment_history
                                            ?.payment_id || d?.razorpay_id}
                                        </td>
                                      )}
                                      {visible.col12 && (
                                        <td>
                                          {d?.book_studio?.payment_history
                                            ?.createdAt || d?.createdAt}
                                        </td>
                                      )}
                                      {visible.col13 && (
                                        <td>
                                          {d?.book_studio?.user
                                            ?.users_subscription_detail
                                            ?.createdAt || "-"}
                                        </td>
                                      )}{" "}
                                      {visible.col14 && (
                                        <td>
                                          {d?.book_studio?.user
                                            ?.users_subscription_detail
                                            ?.expiry_date || "-"}
                                        </td>
                                      )}
                                      {visible.col15 && (
                                        <td>
                                          {d?.book_studio?.user
                                            ?.users_subscription_detail
                                            ?.subscription?.name || "-"}
                                        </td>
                                      )}
                                      {visible.col16 && (
                                        <td>
                                          {d?.book_studio
                                            ?.after_subscription_date || "No"}
                                        </td>
                                      )}
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td colSpan="12" className="text-center">
                                    Data not available
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                          {data?.data?.data?.length > 0 && (
                            <Pagination_Holder
                              handlePageChange={handlePageChange}
                              onPageChange={onPageChange}
                              totalPages={totalPages}
                            />
                          )}
                        </div>
                      ) : (
                        <div className="data">
                          <Table striped bordered hover responsive center>
                            <thead>
                              <tr className="">
                                {visible.col1 && <th className="sr">S. No</th>}
                                <th className="tax-name">Transaction Type </th>
                                {visible.col2 && (
                                  <th className="tax-name">Transaction Id </th>
                                )}{" "}
                                {visible.col3 && (
                                  <th className="tax-name">
                                    Transaction Timestamp{" "}
                                  </th>
                                )}{" "}
                                {visible.col4 && (
                                  <th className="tax-name">Booking Id </th>
                                )}{" "}
                                {visible.col5 && (
                                  <th className="tax-name">Booking Date </th>
                                )}
                                {visible.col6 && (
                                  <th className="tax-name">
                                    Booking Duration{" "}
                                  </th>
                                )}
                                {visible.col7 && (
                                  <th className="tax-name"> Studio Name</th>
                                )}{" "}
                                {visible.col8 && (
                                  <th className="tax-name"> Invoice Number</th>
                                )}{" "}
                                {visible.col9 && (
                                  <th className="tax-name"> Customer Name</th>
                                )}{" "}
                                {visible.col10 && (
                                  <th className="tax-name"> Amount </th>
                                )}{" "}
                                {visible.col11 && (
                                  <th className="tax-name"> Status </th>
                                )}
                                {visible.col12 && (
                                  <th className="tax-name">
                                    Last Status Time Stamp
                                  </th>
                                )}{" "}
                                {visible.col13 && (
                                  <th className="tax-name">Payment Mode</th>
                                )}{" "}
                              </tr>
                            </thead>
                            <tbody>
                              {data?.data?.data?.length > 0 ? (
                                data.data.data.map((d, index) => {
                                  const paginatedIndex =
                                    (onPageChange - 1) * perPage + index + 1;
                                  return (
                                    <tr className="" key={index}>
                                      {visible.col1 && (
                                        <td>{paginatedIndex}.</td>
                                      )}{" "}
                                      <td className="tax-name">
                                        {d?.transaction_type}{" "}
                                      </td>
                                      {visible.col2 && (
                                        <td>
                                          {d?.book_studio?.payment_history
                                            ?.payment_id || d?.razorpay_id}
                                        </td>
                                      )}{" "}
                                      {visible.col3 && (
                                        <td>
                                          {new Date(d?.createdAt)
                                            .toLocaleString("en-GB", {
                                              day: "2-digit",
                                              month: "2-digit",
                                              year: "numeric",
                                              hour: "2-digit",
                                              minute: "2-digit",
                                              second: "2-digit",
                                              hour12: false, // use `true` for 12-hour format with AM/PM
                                            })
                                            .replace(",", "") || ""}
                                        </td>
                                      )}
                                      {visible.col4 && (
                                        <td>{d?.book_studio?.id || "-"}</td>
                                      )}{" "}
                                      {visible.col5 && (
                                        <td>
                                          {d?.book_studio?.start_date || "-"}
                                        </td>
                                      )}
                                      {visible.col6 && (
                                        <td>
                                          {d?.book_studio?.hours
                                            ? d?.book_studio?.hours + " H"
                                            : "-"}
                                        </td>
                                      )}{" "}
                                      {visible.col7 && (
                                        <td>
                                          {d?.book_studio?.studio?.name || "-"}
                                        </td>
                                      )}
                                      {visible.col8 && (
                                        <td>
                                          {d?.book_studio?.invoice_no || "-"}
                                        </td>
                                      )}{" "}
                                      {visible.col9 && (
                                        <td>
                                          {d?.book_studio?.user_id ? (
                                            <>
                                              {d?.book_studio?.user
                                                ?.first_name +
                                                " " +
                                                d?.book_studio?.user?.last_name}
                                              ({d?.book_studio?.user_id})
                                            </>
                                          ) : (
                                            "-"
                                          )}
                                        </td>
                                      )}{" "}
                                      {visible.col10 && <td>₹{d?.amount}</td>}
                                      {visible.col11 && (
                                        <td>
                                          {d?.withdraw_status?.name || "-"}
                                        </td>
                                      )}
                                      {visible.col12 && (
                                        <td>
                                          {new Date(d?.updatedAt)
                                            .toLocaleString("en-GB", {
                                              day: "2-digit",
                                              month: "2-digit",
                                              year: "numeric",
                                              hour: "2-digit",
                                              minute: "2-digit",
                                              second: "2-digit",
                                              hour12: false, // use `true` for 12-hour format with AM/PM
                                            })
                                            .replace(",", "") || "-"}
                                        </td>
                                      )}
                                      {visible.col13 && (
                                        <td>
                                          {d?.book_studio?.payment_type || "-"}
                                        </td>
                                      )}{" "}
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td colSpan="12" className="text-center">
                                    Data not available
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                          {data?.data?.data?.length > 0 && (
                            <Pagination_Holder
                              handlePageChange={handlePageChange}
                              onPageChange={onPageChange}
                              totalPages={totalPages}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>

      {show ? (
        <AddOffCanvance
          handleClose={handleClose}
          setShow={setShowAdd}
          show={show}
        />
      ) : (
        ""
      )}

      {show1 ? (
        <EditOffCanvance
          handleClose={handleClose1}
          setShow={setShowEdit}
          show={show1}
        />
      ) : (
        ""
      )}

      <ModalDelete
        show={showDeleteModal}
        handleDeleteRecord={handleDeleteRecord}
        handleDeleteCancel={handleDeleteCancel}
      />

      <ModelBulkUpload
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
      <Toaster position="top-right" />

      <OffcanvasCon show={showoff} handleClose={() => setShowoff(false)} />

      <Withdrow_request_modal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

      <Withdraw_Recharge_modal
        show={modalShowRecharge}
        onHide={() => setModalShowRecharge(false)}
      />
    </>
  );
};

export default Tables;
