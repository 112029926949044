import React, { useContext, useEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./AdvanceDashboard.css";
import Header from "../../Header/Header";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import "react-circular-progressbar/dist/styles.css";
import "swiper/swiper-bundle.css";
import CanvasJSReact from "@canvasjs/react-charts";
import { Context } from "../../../utils/context";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Accordion from "react-bootstrap/Accordion";
import {
  faCartShopping,
  faBoxOpen,
  faUsers,
  faStore,
} from "@fortawesome/free-solid-svg-icons";
import { Chart } from "react-google-charts";
import DataTable from "datatables.net";
import Button from "react-bootstrap/Button";
import { Link, useLocation } from "react-router-dom";
import { Table } from "react-bootstrap";
import AllProductColumnChart from "./AllProduct-PieChart/Product_pie_chart";
import AllSellerColumnChart from "./AllSellerWiseChart/Product_pie_chart";
import DemoChart from "./DemoChart/demochart";
import DonutDemo from "./DonutChart/donutdemochart";
import DonutDemoPart2 from "./DonutChartpart2/donutdemochart";

library.add(fas);

const AdminDashboard = ({
  dashboardData,
  productForGraph,
  productGraphCount,
  productGraphYear,
  sellerForGraph,
  sellerGraphCount,
  sellerAmountForGraph,
  sellerAmountGraphCount,
}) => {
  return (
    <>
      <Row>
        {/* Total Vendor Code Start */}
        <Col xxl={2} xl={3} lg={3} md={4} sm={6} xs={12} className="mb-3">
          <Card className="small-box" style={{ backgroundColor: "#63bff5" }}>
            <div className="">
              <div className="content">
                <div class="inner">
                  <h3>{dashboardData?.totalvendor}</h3>
                  <p>Total Vendor</p>
                </div>
              </div>

              <Link to="/employee/employee-details">
                <p className="mb-0 small-box-footer">
                  More info{" "}
                  <FontAwesomeIcon icon="fa-solid fa-circle-arrow-right" />
                </p>
              </Link>
            </div>
          </Card>
        </Col>
        {/* Total Vendor Code End */}

        {/* Total Studo Code Start */}
        <Col xxl={2} xl={3} lg={3} md={4} sm={6} xs={12} className="mb-3">
          <Card className="small-box" style={{ backgroundColor: "#63bff5" }}>
            <div className="">
              <div className="content">
                <div class="inner">
                  <h3>{dashboardData?.totalAcceptedstudio}</h3>
                  <p>Total Accepted Studio</p>
                </div>
              </div>

              <Link to="/studio-accept">
                <p className="mb-0 small-box-footer">
                  More info{" "}
                  <FontAwesomeIcon icon="fa-solid fa-circle-arrow-right" />
                </p>
              </Link>
            </div>
          </Card>
        </Col>

        <Col xxl={2} xl={3} lg={3} md={4} sm={6} xs={12} className="mb-3">
          <Card className="small-box" style={{ backgroundColor: "#63bff5" }}>
            <div className="">
              <div className="content">
                <div class="inner">
                  <h3>{dashboardData?.totalRejectedstudio}</h3>
                  <p>Total Rejected Studio</p>
                </div>
              </div>

              <Link to="/studio/rejected">
                <p className="mb-0 small-box-footer">
                  More info{" "}
                  <FontAwesomeIcon icon="fa-solid fa-circle-arrow-right" />
                </p>
              </Link>
            </div>
          </Card>
        </Col>

        {/* Total Studo Code End */}

        {/* Total Customer Code Start */}
        <Col xxl={2} xl={3} lg={3} md={4} sm={6} xs={12} className="mb-3">
          <Card className="small-box" style={{ backgroundColor: "#63bff5" }}>
            <div className="">
              <div className="content">
                <div class="inner">
                  <h3>{dashboardData?.totalcustomer}</h3>
                  <p>Total Customers</p>
                </div>
              </div>

              <Link to="/employee/customer-details">
                <p className="mb-0 small-box-footer">
                  More info{" "}
                  <FontAwesomeIcon icon="fa-solid fa-circle-arrow-right" />
                </p>
              </Link>
            </div>
          </Card>
        </Col>
        {/* Total Studo Code End */}

        {/* Total New Booking Code Start */}
        <Col xxl={2} xl={3} lg={3} md={4} sm={6} xs={12} className="mb-3">
          <Card className="small-box" style={{ backgroundColor: "#63bff5" }}>
            <div className="">
              <div className="content">
                <div class="inner">
                  <h3>₹ {dashboardData?.totalwallet}</h3>
                  <p>Total Wallet Amount</p>
                </div>
              </div>

              <Link to="/wallet-history">
                <p className="mb-0 small-box-footer">
                  More info{" "}
                  <FontAwesomeIcon icon="fa-solid fa-circle-arrow-right" />
                </p>
              </Link>
            </div>
          </Card>
        </Col>
        {/* Total New Booking Code End */}

        {/* Total New Booking Code Start */}
        <Col xxl={2} xl={3} lg={3} md={4} sm={6} xs={12} className="mb-3">
          <Card className="small-box" style={{ backgroundColor: "#63bff5" }}>
            <div className="">
              <div className="content">
                <div class="inner">
                  <h3>{dashboardData?.newbooking}</h3>
                  <p>Total Requested Bookings</p>
                </div>
              </div>

              <Link to="/booking-request">
                <p className="mb-0 small-box-footer">
                  More info{" "}
                  <FontAwesomeIcon icon="fa-solid fa-circle-arrow-right" />
                </p>
              </Link>
            </div>
          </Card>
        </Col>
        {/* Total New Booking Code End */}

        {/* Total New Booking Code Start */}
        <Col xxl={2} xl={3} lg={3} md={4} sm={6} xs={12} className="mb-3">
          <Card className="small-box" style={{ backgroundColor: "#63bff5" }}>
            <div className="">
              <div className="content">
                <div class="inner">
                  <h3>{dashboardData?.acceptedbooking}</h3>
                  <p>Total Accepted Bookings</p>
                </div>
              </div>

              <Link to="/booking-accept">
                <p className="mb-0 small-box-footer">
                  More info{" "}
                  <FontAwesomeIcon icon="fa-solid fa-circle-arrow-right" />
                </p>
              </Link>
            </div>
          </Card>
        </Col>
        {/* Total New Booking Code End */}

        {/* Total New Booking Code Start */}
        <Col xxl={2} xl={3} lg={3} md={4} sm={6} xs={12} className="mb-3">
          <Card className="small-box" style={{ backgroundColor: "#63bff5" }}>
            <div className="">
              <div className="content">
                <div class="inner">
                  <h3>{dashboardData?.rejectedbooking}</h3>
                  <p>Total Rejected Bookings</p>
                </div>
              </div>

              <Link to="/booking-rejected">
                <p className="mb-0 small-box-footer">
                  More info{" "}
                  <FontAwesomeIcon icon="fa-solid fa-circle-arrow-right" />
                </p>
              </Link>
            </div>
          </Card>
        </Col>
        {/* Total New Booking Code End */}

        {/* Total New Booking Code Start */}
        <Col xxl={2} xl={3} lg={3} md={4} sm={6} xs={12} className="mb-3">
          <Card className="small-box" style={{ backgroundColor: "#63bff5" }}>
            <div className="">
              <div className="content">
                <div class="inner">
                  <h3>{dashboardData?.cancelbooking}</h3>
                  <p>Total Cancel Bookings</p>
                </div>
              </div>

              <Link to="/cancel-booking">
                <p className="mb-0 small-box-footer">
                  More info{" "}
                  <FontAwesomeIcon icon="fa-solid fa-circle-arrow-right" />
                </p>
              </Link>
            </div>
          </Card>
        </Col>
        {/* Total New Booking Code End */}
      </Row>
      <div className=" col-12">
        <div className="chart-heading">
          <h1 className="title-classss mt-3"></h1>
        </div>
        {/* {productForGraph?.length > 0 && productGraphCount?.length > 0 && ( */}
        <div className="row">
          {productForGraph ? (
            <div className="col-xl-12 col-lg-12 col-md-12  mb-5">
              <h5 className="mainheaddd">Yearly Booking</h5>
              <p className="submainheaddd">Current Year</p>
              <DemoChart
                productData={productForGraph}
                productCount={productGraphCount}
                year={productGraphYear}
              />
            </div>
          ) : (
            "No Data Found"
          )}

          {sellerForGraph ? (
            <div className="col-xl-6 col-lg-12 col-md-12 mb-5">
              <h5 className="mainheaddd">Studio Booking</h5>
              <p className="submainheaddd">Studio Wise Booking</p>
              <DonutDemo
                sellerForGraph={sellerForGraph}
                sellerGraphCount={sellerGraphCount}
              />
            </div>
          ) : (
            "No Data Found"
          )}

          {sellerAmountForGraph ? (
            <div className="col-xl-6 col-lg-12 col-md-12 mb-5">
              <h5 className="mainheaddd">Vendor</h5>
              <p className="submainheaddd">Vendor Amount</p>
              <DonutDemoPart2
                sellerAmountForGraph={sellerAmountForGraph}
                sellerAmountGraphCount={sellerAmountGraphCount}
              />
            </div>
          ) : (
            "No Data Found"
          )}
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;
