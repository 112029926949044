import React, { useContext } from "react";
import { useState, useEffect } from "react";
// import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
// *******************toster****************************
import Select from "react-select";
import ModalSave from "../../common/ModelSave";
import Validation from "../../common/FormValidation";
import { CancelButton, SaveButton } from "../../common/Button";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PasswordChecklist from "react-password-checklist";
import classNames from "classnames";
import "./PrivateDashboard.css";
library.add(fas);

const EditOffCanvance = (props) => {
  const { postData, getData, Select2Data } = useContext(Context);

  const id = props.show;
  const [country, setCountry] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [pincode, setPincode] = useState([]);

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const GetEditData = async () => {
    const response = await getData(`/employee/employee-details/add`);
    reset(response?.data);
  };

  const GetAllCountries = async () => {
    const response = await getData("/allcountry");

    if (response?.success) {
      setCountry(await Select2Data(response?.data, "country_id"));
    }
  };

  const GetAllStates = async (id) => {
    const response = await getData(`/allstate/${id}`);

    if (response?.success) {
      setStates(await Select2Data(response?.data, "state_id"));
    }
  };

  const GetAllCities = async (id) => {
    const response = await getData(`/allcity/${id}`);

    if (response?.success) {
      setCities(await Select2Data(response?.data, "city_id"));
    }
  };
  const GetAllPincode = async (id) => {
    const response = await getData(`/allpincodes/${id}`);

    if (response?.success) {
      setPincode(await Select2Data(response?.data, "pincode_id"));
    }
  };

  useEffect(() => {
    GetEditData();
    GetAllCountries();
    GetAllStates();
    GetAllCities();
  }, []);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    setValue,
    getValues,
  } = useForm();

  const onSubmit = async (data) => {
    if (password !== passwordCurrent) {
      if (password === passwordAgain) {
        const res = await postData(`/change-password`, data);
        if (res?.success) {
          // props.handleClose();
          reset();
          await setShowModal({ code: res.code, message: res.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 3000);
          // hideModalWithDelay();
          // passwordClose()
        } else {
          if (res?.message?.current_password) {
            setError("current_password", {
              message: res?.message?.current_password,
            });
          }
          if (res?.message?.errors?.current_password) {
            setError("current_password", {
              message: res?.message?.errors?.current_password?.msg,
            });
          }
        }
      } else {
        setError("current_password", { message: "Password must match" });
      }
    } else {
      setError("current_password", {
        message: "Current Password and New Password Not Same",
      });
    }
  };

  const [showPasswordCurrent, setShowPasswordCurrent] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordOne, setShowPasswordOne] = useState(false);
  const [iconOne, setIconOne] = useState(faEyeSlash);
  const [iconTwo, setIconTwo] = useState(faEyeSlash);
  const [iconCurrent, setIconCurrent] = useState(faEyeSlash);

  const togglePasswordVisibility = (field) => {
    if (field === "password") {
      setShowPassword(!showPassword);
      setIconOne(showPassword ? faEyeSlash : faEye);
    } else if (field === "reenterPassword") {
      setShowPasswordOne(!showPasswordOne);
      setIconTwo(showPasswordOne ? faEyeSlash : faEye);
    } else if (field === "current_password") {
      setShowPasswordCurrent(!showPasswordCurrent);
      setIconCurrent(showPasswordCurrent ? faEyeSlash : faEye);
    }
  };
  const [password, setPassword] = useState("");
  const [passwordCurrent, setPasswordCurrent] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  return (
    <>
      <Modal
        {...props}
        onHide={props.handleClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className=""
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Change Password
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={() => handleSubmit(onSubmit)} role="form">
            <Row className="justify-content-center">
              <Col md={10}>
                <div className="main-form-section my-3">
                  <Form.Group>
                    <div className="text-center">
                      <Form.Label>Current Password</Form.Label>
                    </div>
                    <InputGroup>
                      <div className="formfieldMain">
                        <Form.Control
                          type={showPasswordCurrent ? "text" : "password"}
                          placeholder="Enter Current Password*"
                          {...register("current_password", {
                            required: "Current Password is required",
                          })}
                          onChange={(e) => setPasswordCurrent(e.target.value)}
                        />
                        <FontAwesomeIcon
                          className="eyeIcon"
                          icon={iconCurrent}
                          onClick={() =>
                            togglePasswordVisibility("current_password")
                          }
                        />
                      </div>
                    </InputGroup>
                    {errors?.current_password && (
                      <Form.Text className="text-danger d-block">
                        {errors?.current_password?.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </div>
              </Col>

              <Col md={10}>
                <div className="main-form-section my-3">
                  <Form.Group>
                    <div className="text-center">
                      <Form.Label>Password</Form.Label>
                    </div>
                    <InputGroup>
                      <div className="formfieldMain">
                        <Form.Control
                          type={showPassword ? "text" : "password"}
                          placeholder="Enter Password*"
                          {...register("new_password", {
                            required: "New Password is required",
                            minLength: {
                              value: 8,
                              message:
                                "Password must be at least 8 characters long",
                            },
                            pattern: {
                              value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                              message:
                                "Password must contain at least one uppercase letter, one lowercase letter, and one number",
                            },
                          })}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <FontAwesomeIcon
                          icon={iconOne}
                          className="eyeIcon"
                          onClick={() => togglePasswordVisibility("password")}
                        />
                      </div>
                    </InputGroup>
                    {errors.new_password && (
                      <Form.Text className="text-danger d-block">
                        {errors.new_password.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </div>
              </Col>

              <Col md={10}>
                <div className="main-form-section my-3">
                  <Form.Group>
                    <div className="text-center">
                      <Form.Label>Confirm Password</Form.Label>
                    </div>
                    <InputGroup>
                      <div className="formfieldMain">
                        <Form.Control
                          type={showPasswordOne ? "text" : "password"}
                          placeholder="Re-Enter Password*"
                          {...register("re_password", {
                            required: "Re-Password is required",
                          })}
                          onChange={(e) => setPasswordAgain(e.target.value)}
                        />
                        <FontAwesomeIcon
                          icon={iconTwo}
                          className="eyeIcon"
                          onClick={() =>
                            togglePasswordVisibility("reenterPassword")
                          }
                        />
                      </div>
                    </InputGroup>
                    {password && (
                      <PasswordChecklist
                        rules={[
                          "minLength",
                          "specialChar",
                          "number",
                          "capital",
                          "match",
                        ]}
                        minLength={8}
                        value={password}
                        valueAgain={passwordAgain}
                        onChange={(isValid) => {}}
                        style={{
                          fontSize: "10px",
                          padding: "4px",
                          margin: "0",
                        }}
                      />
                    )}
                    {errors.re_password && (
                      <Form.Text className="text-danger d-block">
                        {errors.re_password.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </div>
              </Col>

              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>

                  <SaveButton
                    name={"save"}
                    handleSubmit={handleSubmit(onSubmit)}
                  />
                </div>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
