import React, { useContext, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Context } from "../../../../utils/context";

function DonutDemoPart2(props) {
  const [appSetup, setAppSetup] = useState("");


  const sellerAmounts = props?.sellerAmountGraphCount?.map((amount) =>
    parseFloat(amount)
  );


  const { getData } = useContext(Context);

  const GetAppSetup = async () => {
    try {
      const res = await getData(`/all-app-setup`);
      setAppSetup(res?.data);
    } catch (error) {
      console.error("Error fetching app setup data:", error);
    }
  };

  useEffect(() => {
    GetAppSetup();
  }, []);

  const options = {
    chart: {
      width: 380,
      type: "donut",
    },
    labels: props?.sellerAmountForGraph, // Seller names as labels
    series: sellerAmounts, // Data values with 2 decimal places
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    plotOptions: {
      pie: {
        donut: {
          size: "60%",
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: "16px",
            },
            value: {
              show: true,
              fontSize: "14px",
              color: "#fff",
            },
          },
        },
      },
    },

    dataLabels: {
      enabled: true,
      color: "#fff",
      formatter: function (val, opts) {
        const amount = opts.w.config.series[opts.seriesIndex];
        return `₹${parseFloat(amount).toFixed(2)}`; // Show the amount rounded to 2 decimal places
      },
    },
  };

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={options}
          series={options.series}
          type="donut"
          height={350}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
}

export default DonutDemoPart2;
