import React, { useContext, useEffect, useState } from "react";
import "./Header.css";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Context } from "../../utils/context";
import { useNavigate } from "react-router";

import Cookies from "js-cookie";
import { InputGroup, Offcanvas } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { FaBars } from "react-icons/fa";
import MobileSidebar from "../Sidebar/MobileSidebar";
import ViewOffCanvance from "../DashBoard/PrivateDashboard/Edit";
library.add(fas);

function Header(props) {
  const {
    setUserData,
    setUsertype,
    setSignin,
    usertype,
    isSidebarOpen,
    setSidebarOpen,
    toggleSidebar,
    getData,
    IMG_URL,
  } = useContext(Context);

  const navigate = useNavigate();

  const LogOut = async () => {
    Cookies.remove("flick_and_shoot_admin_security", { path: "/" });
    await setUserData("");
    await setUsertype("");
    await setSignin(false);
    navigate("/");
  };

  const [user, setUser] = useState({});
  const GetUserData = async () => {
    const response = await getData(`/usersingleget`);
    if (response?.success) {
      setUser(response?.data);
    }
  };
  useEffect(() => {
    GetUserData();
  }, []);

  const [show, setShow] = useState(false);

  const handleClose = () => setPassword(false);

  
  const handleShow = () => setShow(true);

  const [view, setView] = useState(false);
  const handleViewClose = () => setView(false);
  const handleViewshow = async () => {
    await setView(true);
  };

  const [password, setPassword] = useState(false);
  const handleshow = async () => {
    await setPassword(true);
  };


  return (
    <>
      <section className="header">
        <Navbar expand="md" className="bg-body-tertiary">
          <Navbar.Brand>
            <div className="header-text">
              <div className="desktop-bar-btnnn">
                <div className="bars" onClick={toggleSidebar}>
                  <FaBars />{" "}
                  <b className=" main-heading-text ms-3">{props.title}</b>
                </div>
              </div>
              <div className="mobile-view-bar">
                <div className="bars" onClick={handleShow}>
                  <FaBars />
                </div>
                <Offcanvas
                  show={show}
                  onHide={handleClose}
                  backdrop="static"
                  style={{ width: "80%" }}
                >
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title>DashBoard</Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <MobileSidebar />
                  </Offcanvas.Body>
                </Offcanvas>
              </div>
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <div className="header-icons">
                {/* <div className="search-area icon">
                  <InputGroup className="input-group-text">
                    <Form.Control
                      placeholder="Search Something here.."
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                    />
                    <InputGroup.Text id="basic-addon2">
                      <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" />
                    </InputGroup.Text>
                  </InputGroup>
                </div> */}

                {/* <div className="header_icons icon  position-relative">
                  <FontAwesomeIcon icon="fa-solid fa-comments" className="" />
                  <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                    5+
                    <span class="visually-hidden">unread messages</span>
                  </span>
                </div> */}

                {/* <div className="header_icons icon position-relative">
                  <FontAwesomeIcon icon="fa-solid fa-bell" />
                  <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                    5+
                    <span class="visually-hidden">unread messages</span>
                  </span>
                </div> */}
                {/* <div className="text-center me-3">
                  <FontAwesomeIcon
                    icon="fa-solid fa-user"
                    className="power-icon"
                    onClick={handleViewshow}
                  />
                </div> */}
                {/* <FontAwesomeIcon  icon="fa-solid fa-user-circle"className="power-icon icon  me-3"/> */}

                <FontAwesomeIcon  onClick={handleshow}  icon="fa-solid fa-shield-halved"className="power-icon icon  me-3"/>
                <div className="icon dropdown ">
                  <div
                    className="text-center dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                      

                    <FontAwesomeIcon
                      icon="fa-solid fa-power-off"
                      className="power-icon"
                    />
                    {/* <p className="super_admintxt">Super Admin</p> */}
                  </div>

                  <ul class="dropdown-menu">
                    {/* <li>
                      <a class="dropdown-item" href="#">
                        <FontAwesomeIcon
                          icon="fa-solid fa-user me-4"
                          className="text-primary"
                        />
                        <span className="ms-3">Profile</span>
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        <FontAwesomeIcon
                          icon="fa-solid fa-message"
                          className="text-success"
                        />
                        <span className="ms-3">Inbox</span>
                      </a>
                    </li> */}

                    <div className="blue-bg">
                      {/* {user?.id === 1 ? (
                        <img
                          src={process.env.PUBLIC_URL + "/avatar.png"}
                          className="avtar-img"
                          alt="..."
                        />
                      ) : (
                        <img
                          onClick={handleshow}
                          src={process.env.PUBLIC_URL + "/avatar.png"}
                          // src={IMG_URL + user?.store_detail?.logo}
                          className="avtar-img"
                          alt="..."
                        />
                        
                      )} */}
                       <img
                          // onClick={handleshow}
                          src={process.env.PUBLIC_URL + "/avatar.png"}
                          // src={IMG_URL + user?.store_detail?.logo}
                          className="avtar-img"
                          alt="..."
                        />

                      <div
                        className="icon dropdown"
                        style={{ textAlign: "center" }}
                      >
                        <p className="mb-0 logout_content_sec">
                          {user?.first_name}
                        </p>
                        <p className="logout_content_secccc">{user?.type}</p>
                      </div>
                    </div>
                    <li>
                      <a class="dropdown-item" href="#" onClick={LogOut}>
                        <button className="logout-btnnn"> Log out</button>
                      </a>
                    </li>
                  </ul>
                </div>
                <p className="mb-0 logout_content">{user?.first_name}</p>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </section>
      {password ? (
        <ViewOffCanvance
          handleClose={handleClose}
          setShow={setPassword}
          show={password}
          user_id={user?.id}
          onHide={() => setPassword(false)}

        />

     
       
      ) : (
        ""
      )}
    </>
  );
}

export default Header;
