import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
// *******************toster****************************
import ModalSave from "../common/ModelSave";
import { CancelButton, SaveButton } from "../common/Button";
import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import Select from "react-select";
library.add(fas);

const EditOffCanvance = (props) => {
  const id = props.show;
  const { postData, getData, IMG_URL, Select2Data } = useContext(Context);
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const GetEditData = async () => {
    const response = await getData(`/subscription/${id}`);
    reset(response?.data);
    setIsDiscountApplied(response?.data?.base_price_discount);
    setFreeBooking(response?.data?.free_booking_in_hours);
    setRechedule(response?.data?.reschedule);
    setPremiumStudios(response?.data?.premium_studios);
    setCustomerSupports(response?.data?.customer_support);
    setPlatformFee(response?.data?.discount_on_platform_fee);
  };
  useEffect(() => {
    GetEditData();
  }, []);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    try {

      const sendData = new FormData();

      const accessPremiumStudioAllValue = data?.access_premium_studio_all
        ? 1
        : 0;

      const customerSupport = data?.customer_support ? 1 : 0;

      const customerSupportInstant = data?.customer_support_instant ? 1 : 0;

      const customerSupportPriority = data?.customer_support_priority ? 1 : 0;

      sendData.append("price", data?.price);
      sendData.append("base_price_percent", data?.base_price_percent);
      sendData.append("base_price_booking_no", data?.base_price_booking_no);
      sendData.append("free_booking_hours", data?.free_booking_hours);
      sendData.append(
        "free_booking_hours_booking_no",
        data?.free_booking_hours_booking_no
      );
      sendData.append("rechedule_month", data?.rechedule_month);
      sendData.append(
        "access_premium_studio_no",
        data?.access_premium_studio_no
      );
      sendData.append("customer_support", customerSupport);
      sendData.append("access_premium_studio_all", accessPremiumStudioAllValue);
      sendData.append("customer_support_instant", customerSupportInstant);
      sendData.append("customer_support_priority", customerSupportPriority);
      sendData.append("base_price_discount", isDiscountApplied ? 1 : 0);
      sendData.append("free_booking_in_hours", freeBooking ? 1 : 0);
      sendData.append("rechedule", rechedule ? 1 : 0);
      sendData.append("premium_studios", premiumStudios ? 1 : 0);
      sendData.append("discount_on_platform_fee", platformFee ? 1 : 0);
      sendData.append("platform_fee_discount", data?.platform_fee_discount);

      const studios = [];
      data.studios.forEach((variant) => {
        const addOn = {
          studio_id: variant.value,
        };

        studios.push(addOn);
      });

      sendData.append("studios", JSON.stringify(studios));

      const response = await postData(`/subscription/${id}`, sendData);

      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const [isDiscountApplied, setIsDiscountApplied] = useState("");

  const handleCheckboxChange = (e) => {
    setIsDiscountApplied(e.target.checked);
    // Further logic for discount application can go here
  };

  const [freeBooking, setFreeBooking] = useState("");

  const handleFreeBookingCheckboxChange = (e) => {
    setFreeBooking(e.target.checked);
  };

  const [rechedule, setRechedule] = useState("");

  const handleRecheduleCheckboxChange = (e) => {
    setRechedule(e.target.checked);
  };

  const [premiumStudios, setPremiumStudios] = useState("");

  const handlePremiumCheckboxChange = (e) => {
    setPremiumStudios(e.target.checked);
  };

  const [customerSupport, setCustomerSupports] = useState("");

  const handleCustomerSupportCheckboxChange = (e) => {
    setCustomerSupports(e.target.checked);
  };

  const [platformFee, setPlatformFee] = useState("");

  const handlePlatformFeeDiscountCheckboxChange = (e) => {
    setPlatformFee(e.target.checked);
  };

  const [acceptedStudio, setAcceptedStudio] = useState([]);


  const GetAllAcceptedStudios = async () => {
    const response = await getData("/all-accepted-studios");
    if (response?.success) {
      setAcceptedStudio(await Select2Data(response?.data, "add_onn_id"));
    }
  };

  useEffect(() => {
    GetAllAcceptedStudios();
  }, []);

  return (
    <>
      <Modal
        {...props}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="edit-modal-holder"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Subscription
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={() => handleSubmit(onSubmit)} role="form">
            <Row className="justify-content-center">
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Group>
                      <div className="text-center">
                        <Form.Label>Name</Form.Label>
                      </div>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="name"
                          disabled
                          placeholder="Name"
                          className={classNames("", {
                            "is-invalid": errors?.name,
                          })}
                          {...register("name", {
                            required: "Name is required",
                          })}
                        />
                      </InputGroup>
                      {errors.name && (
                        <span className="text-danger">
                          {errors.name.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Group>
                      <div className="text-center">
                        <Form.Label>Price</Form.Label>
                      </div>
                      <InputGroup>
                        <Form.Control
                          type="number"
                          name="price"
                          placeholder="Price"
                          className={classNames("", {
                            "is-invalid": errors?.price,
                          })}
                          {...register("price", {
                            required: "Price is required",
                          })}
                        />
                      </InputGroup>
                      {errors.price && (
                        <span className="text-danger">
                          {errors.price.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <hr className="hr-line-holder " />

              <div className="checkbox-holder">
                <input
                  type="checkbox"
                  id="basePriceDiscount"
                  {...register("base_price_discount")}
                  onChange={(e) => {
                    handleCheckboxChange(e);
                    // You can also call form methods from `register` here if needed
                  }}
                />
                <label htmlFor="basePriceDiscount">Base Price Discount</label>
              </div>

              {isDiscountApplied ? (
                <>
                  {" "}
                  <Col md={6}>
                    <div className="main-form-section mt-3">
                      <Row className="justify-content-center">
                        <Form.Group>
                          <div className="text-center">
                            <Form.Label>Base Price Percent</Form.Label>
                          </div>
                          <InputGroup>
                            <Form.Control
                              type="number"
                              name="base_price_percent"
                              placeholder="Percent Of Base Price"
                              className={classNames("", {
                                "is-invalid": errors?.base_price_percent,
                              })}
                              {...register("base_price_percent", {
                                required: "Percent Of Base Price is required",
                              })}
                            />
                          </InputGroup>
                          {errors.base_price_percent && (
                            <span className="text-danger">
                              {errors.base_price_percent.message}
                            </span>
                          )}
                        </Form.Group>
                      </Row>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="main-form-section mt-3">
                      <Row className="justify-content-center">
                        <Form.Group>
                          <div className="text-center">
                            <Form.Label>On Every Booking</Form.Label>
                          </div>
                          <InputGroup>
                            <Form.Control
                              type="number"
                              name="base_price_booking_no"
                              placeholder="Booking Number Of Base Price"
                              className={classNames("", {
                                "is-invalid": errors?.base_price_booking_no,
                              })}
                              {...register("base_price_booking_no", {
                                required:
                                  "Booking Number Base Price is required",
                              })}
                            />
                          </InputGroup>
                          {errors.base_price_booking_no && (
                            <span className="text-danger">
                              {errors.base_price_booking_no.message}
                            </span>
                          )}
                        </Form.Group>
                      </Row>
                    </div>
                  </Col>
                </>
              ) : (
                ""
              )}

              <hr className="hr-line-holder " />

              <div className="checkbox-holder">
                <input
                  type="checkbox"
                  id="basePriceDiscount"
                  name="free_booking_in_hours"
                  {...register("free_booking_in_hours")}
                  onChange={(e) => handleFreeBookingCheckboxChange(e)}
                />
                <label htmlFor="basePriceDiscount">Free Booking In Hours</label>
              </div>

              {freeBooking ? (
                <>
                  {" "}
                  <Col md={6}>
                    <div className="main-form-section mt-3">
                      <Row className="justify-content-center">
                        <Form.Group>
                          <div className="text-center">
                            <Form.Label>Free Booking Hours</Form.Label>
                          </div>
                          <InputGroup>
                            <Form.Control
                              type="number"
                              name="free_booking_hours"
                              placeholder="Free Booking hours"
                              className={classNames("", {
                                "is-invalid": errors?.free_booking_hours,
                              })}
                              {...register("free_booking_hours", {
                                required: "Free Booking Hours is required",
                              })}
                            />
                          </InputGroup>
                          {errors.free_booking_hours && (
                            <span className="text-danger">
                              {errors.free_booking_hours.message}
                            </span>
                          )}
                        </Form.Group>
                      </Row>
                    </div>
                  </Col>{" "}
                  <Col md={6}>
                    <div className="main-form-section mt-3">
                      <Row className="justify-content-center">
                        <Form.Group>
                          <div className="text-center">
                            <Form.Label>
                              Free Booking Hours In Every Bookings
                            </Form.Label>
                          </div>
                          <InputGroup>
                            <Form.Control
                              type="number"
                              name="free_booking_hours_booking_no"
                              placeholder="Free Booking hours"
                              className={classNames("", {
                                "is-invalid":
                                  errors?.free_booking_hours_booking_no,
                              })}
                              {...register("free_booking_hours_booking_no", {
                                required: "Free Booking Hours is required",
                              })}
                            />
                          </InputGroup>
                          {errors.free_booking_hours_booking_no && (
                            <span className="text-danger">
                              {errors.free_booking_hours_booking_no.message}
                            </span>
                          )}
                        </Form.Group>
                      </Row>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="main-form-section mt-3">
                      <Row className="row justify-content-center mb-2">
                        <Form.Label>Studios</Form.Label>
                        <Controller
                          name="studios"
                          control={control}
                          rules={{ required: "Select at least one Studio" }}
                          render={({ field }) => {
                            return (
                              <Select
                                {...field}
                                isMulti
                                options={acceptedStudio}
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: errors.studios
                                      ? "red"
                                      : baseStyles.borderColor,
                                  }),
                                }}
                              />
                            );
                          }}
                        />
                        {errors.studios && (
                          <span className="text-danger">
                            {errors.studios.message}
                          </span>
                        )}
                      </Row>
                    </div>
                  </Col>
                </>
              ) : (
                ""
              )}

              {/* <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Group>
                      <div className="text-center">
                        <Form.Label>Studio</Form.Label>
                      </div>
                      <InputGroup>
                        <Form.Control
                          type="number"
                          name="free_booking_hours_booking_no"
                          placeholder="Free Booking hours"
                          className={classNames("", {
                            "is-invalid": errors?.free_booking_hours_booking_no,
                          })}
                          {...register("free_booking_hours_booking_no", {
                            required: "Free Booking Hours is required",
                          })}
                        />
                      </InputGroup>
                      {errors.free_booking_hours_booking_no && (
                        <span className="text-danger">
                          {errors.free_booking_hours_booking_no.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col> */}

              <hr className="hr-line-holder " />

              <div className="checkbox-holder">
                <input
                  type="checkbox"
                  id="basePriceDiscount"
                  name="reschedule"
                  {...register("reschedule")}
                  onChange={(e) => handleRecheduleCheckboxChange(e)}
                />
                <label htmlFor="basePriceDiscount">Rechedule</label>
              </div>

              {rechedule ? (
                <Col md={6}>
                  <div className="main-form-section mt-3">
                    <Row className="justify-content-center">
                      <Form.Group>
                        <div className="text-center">
                          <Form.Label>Rechedule Month</Form.Label>
                        </div>
                        <InputGroup>
                          <Form.Control
                            type="number"
                            name="rechedule_month"
                            placeholder="Rechedule Month Is Required"
                            className={classNames("", {
                              "is-invalid": errors?.rechedule_month,
                            })}
                            {...register("rechedule_month", {
                              required: "Rechedule Month is Required",
                            })}
                          />
                        </InputGroup>
                        {errors.rechedule_month && (
                          <span className="text-danger">
                            {errors.rechedule_month.message}
                          </span>
                        )}
                      </Form.Group>
                    </Row>
                  </div>
                </Col>
              ) : (
                ""
              )}

              <hr className="hr-line-holder " />

              <div className="checkbox-holder">
                <input
                  type="checkbox"
                  id="basePriceDiscount"
                  name="premium_studios"
                  {...register("premium_studios")}
                  onChange={(e) => handlePremiumCheckboxChange(e)}
                />
                <label htmlFor="basePriceDiscount">Premium Studios</label>
              </div>

              {premiumStudios ? (
                <>
                  <Col md={6}>
                    <div className="main-form-section mt-3">
                      <Row className="justify-content-center">
                        <Form.Group>
                          <div className="text-center">
                            <Form.Label>Access Premium Studio No</Form.Label>
                          </div>
                          <InputGroup>
                            <Form.Control
                              type="number"
                              name="access_premium_studio_no"
                              placeholder="Access Premium Studio No"
                              className={classNames("", {
                                "is-invalid": errors?.access_premium_studio_no,
                              })}
                              {...register("access_premium_studio_no", {
                                required:
                                  "Access Premium Studio No is Required",
                              })}
                            />
                          </InputGroup>
                          {errors.access_premium_studio_no && (
                            <span className="text-danger">
                              {errors.access_premium_studio_no.message}
                            </span>
                          )}
                        </Form.Group>
                      </Row>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="main-form-section mt-3">
                      <Row className="justify-content-center">
                        {/* Add Checkbox for Premium Studio Access */}
                        <Form.Group className="mt-3 allow-access">
                          {" "}
                          <div className="text-center">
                            <Form.Label>Access Premium Studio All</Form.Label>
                          </div>
                          <Form.Check
                            type="checkbox"
                            label="Allow Access to All Premium Studios"
                            name="access_premium_studio_all"
                            className={classNames("", {
                              "is-invalid": errors?.access_premium_studio_all,
                            })}
                            {...register("access_premium_studio_all", {
                              // required: "This option is required to continue.",
                            })}
                          />
                          {errors.access_premium_studio_all && (
                            <span className="text-danger">
                              {errors.access_premium_studio_all.message}
                            </span>
                          )}
                        </Form.Group>
                      </Row>
                    </div>
                  </Col>
                </>
              ) : (
                ""
              )}

              <hr className="hr-line-holder " />

              <div className="checkbox-holder">
                <input
                  type="checkbox"
                  id="basePriceDiscount"
                  name="customer_support"
                  {...register("customer_support")}
                  onChange={(e) => handleCustomerSupportCheckboxChange(e)}
                />
                <label htmlFor="basePriceDiscount">Customer Support</label>
              </div>

              {customerSupport ? (
                <>
                  {" "}
                  <Col md={6}>
                    <div className="main-form-section mt-3">
                      <Row className="justify-content-center">
                        <Form.Group className="allow-access">
                          <div className="text-center">
                            <Form.Label>Customer Support Instant</Form.Label>
                          </div>
                          <InputGroup>
                            <Form.Check
                              type="checkbox"
                              label="Customer Support Instant"
                              name="customer_support_instant"
                              className={classNames("", {
                                "is-invalid": errors?.customer_support_instant,
                              })}
                              {...register("customer_support_instant", {
                                // required:
                                //   "Customer Support Instant required to continue.",
                              })}
                            />
                          </InputGroup>
                          {errors.customer_support_instant && (
                            <span className="text-danger">
                              {errors.customer_support_instant.message}
                            </span>
                          )}
                        </Form.Group>
                      </Row>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="main-form-section mt-3">
                      <Row className="justify-content-center">
                        <Form.Group className="allow-access">
                          <div className="text-center">
                            <Form.Label>Customer Support Priority</Form.Label>
                          </div>
                          <InputGroup>
                            <Form.Check
                              type="checkbox"
                              label="Customer Support Priority"
                              name="customer_support_priority"
                              className={classNames("", {
                                "is-invalid": errors?.customer_support_priority,
                              })}
                              {...register("customer_support_priority", {
                                // required:
                                //   "Customer Support Priority required to continue.",
                              })}
                            />
                          </InputGroup>
                          {errors.customer_support_priority && (
                            <span className="text-danger">
                              {errors.customer_support_priority.message}
                            </span>
                          )}
                        </Form.Group>
                      </Row>
                    </div>
                  </Col>
                </>
              ) : (
                ""
              )}

              <hr className="hr-line-holder " />

              <div className="checkbox-holder">
                <input
                  type="checkbox"
                  id="basePriceDiscount"
                  name="discount_on_platform_fee"
                  {...register("discount_on_platform_fee")}
                  onChange={(e) => handlePlatformFeeDiscountCheckboxChange(e)}
                />
                <label htmlFor="basePriceDiscount">PlatFrom Fee Discount</label>
              </div>

              {platformFee ? (
                <Col md={6}>
                  <div className="main-form-section mt-3">
                    <Row className="justify-content-center">
                      <Form.Group>
                        <div className="text-center">
                          <Form.Label>PlatForm Fee Discount</Form.Label>
                        </div>
                        <InputGroup>
                          <Form.Control
                            type="number"
                            name="platform_fee_discount"
                            placeholder="PlatForm Fee Discount"
                            className={classNames("", {
                              "is-invalid": errors?.platform_fee_discount,
                            })}
                            {...register("platform_fee_discount", {
                              required: "PlatForm Fee Discount is Required",
                            })}
                          />
                        </InputGroup>
                        {errors.platform_fee_discount && (
                          <span className="text-danger">
                            {errors.platform_fee_discount.message}
                          </span>
                        )}
                      </Form.Group>
                    </Row>
                  </div>
                </Col>
              ) : (
                ""
              )}

              <hr className="hr-line-holder " />

              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>

                  <SaveButton
                    name={"save"}
                    handleSubmit={handleSubmit(onSubmit)}
                  />
                </div>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
